import { useStaticQuery, graphql } from "gatsby"

export const useMetaCollectionConventions = () => {
  return useStaticQuery(graphql`
    query MetaCollectionConventions {
      conventions: markdownRemark(
        fields: {
          contentKind: { eq: "meta" }
          slug: { eq: "collection/conventions" }
        }
      ) {
        html
        plain: excerpt(format: PLAIN, truncate: false)
      }
    }
  `).conventions
}
